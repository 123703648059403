import SpecificationIcon from '../public/svg/specification.svg';
import DesignIcon from '../public/svg/design.svg';
import WebIcon from '../public/svg/web.svg';
import TestIcon from '../public/svg/testing.svg';
import SupportIcon from '../public/svg/support.svg';

const tabs = [
  {
    id: 1,
    Icon: SpecificationIcon,
    title: 'Specification',
    content: {
      timing: {
        time: '1-3 Weeks',
        result: [
          {
            id: 1,
            text: 'MindMap',
          },
          {
            id: 2,
            text: 'Clickable black and white prototype',
          },
          {
            id: 3,
            text: 'List of the user stories with scenarios and acceptance criteria',
          },
        ],
      },
      description:
        'On this stage we identify the requirements to the project, build the main structure, create the user flow and present the UX of the app on the black and white mockups. Thus we get the full picture of how the future project will look like, what are the interactions between the screens and what functionality each screen will include.',
    },
  },
  {
    id: 2,
    Icon: DesignIcon,
    title: 'Design',
    content: {
      timing: {
        time: '1-3 Weeks',
        result: [
          {
            id: 1,
            text: 'Moodboard with the different design references',
          },
          {
            id: 2,
            text: 'Design concept of the main screens to approve the idea',
          },
          {
            id: 3,
            text: 'UI design of all the screens in one style',
          },
          {
            id: 4,
            text: 'Figma source file with the designs and UI Kit',
          },
        ],
      },
      description:
        'Design stage is about the look and feel of your project. To meet your expectations about the visual part of the app we create the board with the absolutely different design samples. This helps us to identify your design preferences and create the unique UI concept for your project!',
    },
  },
  {
    id: 3,
    Icon: WebIcon,
    title: 'Development',
    content: {
      timing: {
        time: '1-6 months',
        result: [
          {
            id: 1,
            text: 'Moodboard with the different design references',
          },
          {
            id: 2,
            text: 'iOS (Swift, Objective-C)',
          },
          {
            id: 3,
            text: 'Android (Kotlin, Java)',
          },
          {
            id: 4,
            text: 'Web Frontend (React.js, Vue.js, Nuxt.js, Typescript, Redux, Bootstrap, Webpack)',
          },
          {
            id: 5,
            text: 'Web Backend (PHP, Python)',
          },
          {
            id: 6,
            text: 'Bluetooth Technologies',
          },
        ],
      },
      description:
        'Here we start the implementation of the project. The development is always divided into 2 weeks stages. In the end of each stage we provide you with the demo presentation so you could stay on the same page with us during the whole development process and be sure that we follow the development plan.',
    },
  },
  {
    id: 4,
    Icon: TestIcon,
    title: 'Testing',
    content: {
      description:
        'We start testing right from the Wireframing and Design stages. The QA team responds quickly by reporting all bugs and issues. Thus, testing for us is like a continuous refinement process in our pursuit to perfection. It begins with the project and never really ends until the project goes live.',
    },
  },
  {
    id: 5,
    Icon: SupportIcon,
    title: 'Support',
    content: {
      description:
        'When the apps are developed, we publish them to App Store and Play Market. We provide you with the 3-months warranty and monitor the project in live. You may also sign the Support Contract with us and get the regular app updates according to the new OS versions, libraries and technologies.',
    },
  },
];

export default tabs;
