import Head from 'next/head';
import { GetServerSideProps } from 'next';

import previewMain from '../public/preview/preview-main.png';
import { getAllArticles } from '../services/blog.service';
import { Header, Footer } from '../components';
import HomePage, { HomePageProps } from './home/home';
import staticWorks from '../utils/works';
import menu from '../utils/menu';

export default function Home({ posts, works }: HomePageProps) {
  return (
    <>
      <Head>
        <title>
          Mavinx - Empowering Businesses with Innovative Solutions and Expertise
        </title>
        <meta
          property="og:title"
          content="Mavinx - Empowering Businesses with Innovative Solutions and Expertise"
        />
        <meta property="og:image" content={`${previewMain.src}`} />
        <meta name="twitter:image" content={`${previewMain.src}`} />
        <meta property="og:image:alt" content="MOBILE AND WEB EXPERTS" />
        <meta property="og:type" content="product" />
        <meta
          property="og:description"
          content="Discover how Mavinx empowers organizations with innovative technological solutions and expertise on their website. Mavinx helps organizations prosper in the digital era through digital transformation, bespoke software development, and consulting services. Discover their dedication to innovation and track record of providing significant business solutions."
        />

        <meta
          name="description"
          content="Discover how Mavinx empowers organizations with innovative technological solutions and expertise on their website. Mavinx helps organizations prosper in the digital era through digital transformation, bespoke software development, and consulting services. Discover their dedication to innovation and track record of providing significant business solutions."
        />
        <meta
          name="keywords"
          content="Mavinx, technology solutions, digital transformation, software development, consulting services, innovation, expertise, business solutions"
        />
      </Head>
      <Header appearance="transparent" appearanceLogo="primary" menu={menu} />
      <HomePage posts={posts} works={works} />
      <Footer />
    </>
  );
}

export const getServerSideProps: GetServerSideProps<
  HomePageProps
> = async () => {
  try {
    const posts = await getAllArticles();
    const works = staticWorks.slice(0, 4);
    return {
      props: {
        posts,
        works,
      },
    };
  } catch {
    return {
      notFound: true,
    };
  }
};
