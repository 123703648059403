const menu = [
  {
    id: 1,
    href: '/works',
    label: 'Works',
  },

  {
    id: 2,
    label: 'Expertise',
    drop: [
      {
        id: 1,
        href: '/healthcare',
        label: 'Healthcare',
      },
      {
        id: 2,
        href: '/real-estate',
        label: 'Real Estate',
      },
      {
        id: 3,
        href: '/nft',
        label: 'Blockchain NFT Marketplace',
      },
    ],
  },
  {
    id: 3,
    label: 'Services',
    drop: [
      {
        id: 1,
        href: '/design',
        label: 'Design',
      },
      {
        id: 2,
        href: '/web-development',
        label: 'Web development',
      },
      {
        id: 5,
        href: '/mobile-development',
        label: 'Mobile Development',
      },
      {
        id: 3,
        href: '/ios',
        label: 'iOS',
      },
      {
        id: 4,
        href: '/android',
        label: 'Android',
      },
    ],
  },
  {
    id: 4,
    href: '/team',
    label: 'About Us',
  },
  {
    id: 5,
    href: '/blog',
    label: 'Blog',
  },
];

export default menu;
